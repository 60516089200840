import * as R from 'ramda'
import api from './api';

export default {
  namespaced: true,
  state: {
    builds: [],
    count: 0,
    build: null,
    filters: {}
  },
  mutations: {
    setBuilds(state, builds) {
      state.builds = builds;
    },
    setBuildsNumber(state, count) {
      state.count = count;
    },
    setBuild(state, build) {
      state.build = build;
    },
    setFilters(state, filters) {
      state.filters = filters
    },
    backupFilters(state, filters) {
      state.filters = filters
    }
  },
  getters: {
    getBuilds: state => state.builds,
    getBuildsNumber: state => state.count,
    getBuild: state => state.build,
    getFilters: state => state.filters,
  },
  actions: {
    getBuilds(context, data = {}) {
      // If we are searching builds from a specific application, let's use the
      // correct endpoint.
      if (R.has('application_id', data)) {
        return context.dispatch(
          'getBuildsByApplicationId',
          data
        );
      }

      context.commit('backupFilters', data)
      return api.get(data)
        .then((result) => {
          context.commit('setBuilds', result.data.data);
          context.commit('setBuildsNumber', result.data.meta.count);
        })
    },
    getLatestBuilds(context) {
      const data = {
        page: 1,
        limit: 10,
        sortBy: 'updated_at',
        order: 'desc',
      };

      return context.dispatch('getBuilds', data)
    },
    getBuildsByApplicationId(context, data) {
      if (!R.has('application_id', data)) {
        return context.dispatch(
          'getBuilds',
          data
        );
      }

      context.commit('backupFilters', data)
      return api.getByApplicationId(data.application_id, R.omit(['application_id'], data))
        .then((result) => {
          context.commit('setBuilds', result.data.data);
          context.commit('setBuildsNumber', result.data.meta.count);
        })
    },
    getBuildById(context, id) {
      return api.find(id)
        .then((result) => {
          result.data.data.parsedPayload = JSON.parse(result.data.data.payload);
          return context.commit('setBuild', result.data.data);
        })
    },
    deleteBuildById: (context, id) => api.purge(id),
    updateBuild(context) {
      const payload = {
        payload: JSON.stringify(context.state.build.parsedPayload),
        state: context.state.build.state,
        release_name: context.state.build.release_name,
        commit: context.state.build.commit,
      };

      return api.update(context.state.build.id, payload)
    },
    refresh(context) {
      if (context.state.build) {
        return context.dispatch('getBuildById', context.state.build.id)
      }

      return context.dispatch('getBuilds', context.state.filters)
    },
    rollbackBuild(context, data) {
      return api.rollback(context.state.build.id, data)
    },
    restartBuild(context) {
      return api.restart(context.state.build.id)
    },
  }
}
