import {isNullOrEmpty} from "@/helpers";

const parseBooleanConfiguration = (configuration, defaultValue) => {
  if (isNullOrEmpty(configuration)) {
    return defaultValue;
  }

  return !!+configuration;
};

export {
  parseBooleanConfiguration,
}
