import api from './api';
import router from '../../../router';

export default {
  namespaced: true,
  state: {
    error: null,
    user: {
      metadata: {
        name: '',
        picture: ''
      }
    },
    token: null,
    locked: false
  },
  mutations: {
    setError(state, error) {
      state.error = error;
    },
    setToken(state, token) {
      state.token = token
    },
    setUser(state, user) {
      state.user = user
    }
  },
  getters: {
    getError: state => state.error,
    getToken: state => state.token,
    getUser: state => state.user,
  },
  actions: {
    loginWithGoogle(context, code) {
      return api.loginWithGoogle({ code })
        .then((response) => {
          localStorage.setItem('token', response.data.data)
          context.commit('setToken', response.data.data)
          context.commit('setUser', response.data.meta.user)
        })
    },
    validate(context) {
      const token = localStorage.getItem('token');

      if (!token) {
        return
      }

      return api.validate({token})
        .then((response) => {
          context.commit('setUser', response.data.data);
          context.commit('setToken', token);
          return response.data.data
        })
        .catch((err) => err)
    }
  }
}
