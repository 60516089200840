import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Authentication/AuthLayout';
import NotFound from '@/views/NotFoundPage';

const routes = [
  {
    path: '/dashboard',
    redirect: 'dashboard',
    meta: {
      public: false,
      role: 'READ'
    },
    component: DashboardLayout,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
          public: false,
          role: 'READ'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/Dashboard.vue')
      },
      {
        path: '/builds',
        name: 'builds',
        meta: {
          public: false,
          role: 'READ'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Build/BuildList'),
      },
      {
        path: '/configuration',
        name: 'Configuration',
        meta: {
          public: false,
          role: 'ADMIN'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Configuration/Configuration'),
      },
      {
        path: '/builds/:id',
        name: 'Build detail',
        meta: {
          public: false,
          role: 'READ'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Build/BuildDetail')
      },
      {
        path: '/builds/:id/edit',
        name: 'Edit Build',
        meta: {
          public: false,
          role: 'WRITE'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Build/BuildEdit')
      },
      {
        path: '/applications',
        name: 'applications',
        meta: {
          public: false,
          role: 'READ'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Application/ApplicationList')
      },
      {
        path: '/applications/:id',
        name: 'Application detail',
        meta: {
          public: false,
          role: 'READ'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Application/ApplicationDetail')
      },
      {
        path: '/users',
        name: 'Users',
        meta: {
          public: false,
          role: 'ADMIN'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/User/UserList')
      },
      {
        path: '/users/:id',
        name: 'User detail',
        meta: {
          public: false,
          role: 'ADMIN'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/User/UserDetail')
      },
      {
        path: '/users/:id/edit',
        name: 'Edit user',
        meta: {
          public: false,
          role: 'ADMIN'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/User/UserEdit')
      },
      {
        path: '/slaves',
        name: 'Slaves',
        meta: {
          public: false,
          role: 'ADMIN'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Slave/SlaveList')
      },
      {
        path: '/slaves/new',
        name: 'Slaves',
        meta: {
          public: false,
          role: 'ADMIN'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Slave/SlaveCreate')
      },
      {
        path: '/slaves/:id',
        name: 'Slave detail',
        meta: {
          public: false,
          role: 'ADMIN'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Slave/SlaveDetail')
      },
      {
        path: '/slaves/:id/edit',
        name: 'Edit slave',
        meta: {
          public: false,
          role: 'ADMIN'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Slave/SlaveEdit')
      },
      {
        path: '*',
        component: NotFound,
        meta: {
          public: true
        },
      }
    ]
  },
  {
    path: '/',
    redirect: 'login',
    meta: {
      public: true
    },
    component: AuthLayout,
    children: [
      {
        path: '/',
        name: 'login',
        meta: {
          public: true
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Authentication/Login.vue')
      },
      {
        path: '/login/:type',
        name: 'Login Handler',
        meta: {
          public: true
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Authentication/Processing')
      },
      {
        path: '*',
        component: NotFound,
        meta: {
          public: true
        },
      }
    ]
  },
  {
    path: '/notfound',
    component: NotFound,
    meta: {
      public: true
    },
  }
];

export default routes;
