import { hasPermission } from '@/helpers';
import store from '@/store'

const handleError = (error, next, to) => {
  if ((error.response.status === 403 || error.response.status === 401) && !error.response.data.valid) {
    localStorage.removeItem('token');
    store.commit('authentication/setError', 'Session expired. Please login again')
    return next({
      path: '/',
      params: { nextUrl: to.fullPath },
    });
  }

  return next();
}

const validateToken = (next, to) =>
  store.dispatch('authentication/validate')
  .then((user) => {
    if (user instanceof Error) {
      return handleError(user, next, to)
    }

    if (!hasPermission(user.role, to.meta.role)) {
      return next({
        path: '/notfound',
      });
    }

    return next();
  })
  .catch((error) => handleError(error, next, to));

export default validateToken;
