import axios from '../../../plugins/axios'

const get = (params) => axios.get('/api/v1/applications', { params });
const getBuildEnvironments = (id) => axios.get(`/api/v1/applications/${id}/build-environments`);

const find = (id) => axios.get(`/api/v1/applications/${id}`);

export default {
  get,
  find,
  getBuildEnvironments,
}
