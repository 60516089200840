<template>
  <div>
    <router-view />
    <vue-element-loading
      :active="loading > 0"
      :is-full-screen="true"
    />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters({
      loading: 'loading',
    })
  }
}
</script>
