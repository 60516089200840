import Axios from 'axios';
import config from '../../config';
import store from '../../store'
const client = Axios;

const loginRoutes = [
  '/api/v1/auth/google',
  '/api/v1/auth/verify',
];

client.defaults.baseURL = config.getParameter('api_url');

client.interceptors.request.use(
  (axiosConfig) => {
    if (!loginRoutes.includes(axiosConfig.url)) {
      store.commit('addLoadingRequest', null, { root: true })
    }

    const token = localStorage.getItem('token');
    if (token) {
      axiosConfig.headers.Authorization = `Bearer ${token}`;
    }
    return axiosConfig;
  },
  error => Promise.reject(error),
);

client.interceptors.response.use(
  (response)  => {
    if (!loginRoutes.includes(response.config.url)) {
      store.commit('removeLoadingRequest', null, { root: true })
    }

    return response
  },
  (error) => {
    // Only send errors not related with the authorization
    if (!loginRoutes.includes(error.config.url)) {
      store.commit('removeLoadingRequest', null, { root: true })
    }

    return Promise.reject(error);
  }
);

export default client;
