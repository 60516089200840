import axios from '../../../plugins/axios'

const get = (params) => axios.get('/api/v1/builds', { params });

const find = (id) => axios.get(`/api/v1/builds/${id}?history=true`);

const purge = (id) => axios.delete(`/api/v1/builds/${id}`);

const update = (id, data) => axios.put(`/api/v1/builds/${id}`, data);

const getByApplicationId = (id, params) => axios.get(`/api/v1/applications/${id}/builds`, { params });

const rollback = (id, data ) => axios.put(`/api/v1/builds/${id}/rollback`, data);

const restart = (id) => axios.post(`/api/v1/builds/${id}/restart`, {});

export default {
  get,
  find,
  purge,
  update,
  getByApplicationId,
  rollback,
  restart,
}
