<template>
  <div class="wrapper">
    <Notifications />
    <side-bar>
      <template slot="links">
        <sidebar-item
          v-if="hasPermission(user.role, 'READ')"
          :link="{
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'ni ni-tv-2 text-primary',
          }"
        />
        <sidebar-item
          v-if="hasPermission(user.role, 'READ')"
          :link="{
            name: 'Applications',
            path: '/applications',
            icon: 'ni ni-app text-blue'
          }"
        />
        <sidebar-item
          v-if="hasPermission(user.role, 'READ')"
          :link="{
            name: 'Builds',
            path: '/builds',
            icon: 'ni ni-planet text-blue'
          }"
        />
        <sidebar-item
          v-if="hasPermission(user.role, 'ADMIN')"
          :link="{
            name: 'Configurations',
            path: '/configuration',
            icon: 'ni ni-settings-gear-65 text-blue'
          }"
        />
        <sidebar-item
          v-if="hasPermission(user.role, 'ADMIN')"
          :link="{
            name: 'Slaves',
            path: '/slaves',
            icon: 'ni ni-world text-blue'
          }"
        />
        <sidebar-item
          v-if="hasPermission(user.role, 'ADMIN')"
          :link="{
            name: 'Users',
            path: '/users',
            icon: 'ni ni-single-02 text-blue'
          }"
        />
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType" />

      <div @click="displaySidebar(false)">
        <fade-transition
          :duration="200"
          origin="center top"
          mode="out-in"
        >
          <router-view />
        </fade-transition>
      </div>
    </div>
  </div>
</template>
<script>
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';
  import { hasPermission } from '@/helpers';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import DashboardNavbar from './DashboardNavbar.vue';
  import Notifications from '@/components/NotificationPlugin/Notifications';
  import SidebarItem from '@/components/SidebarPlugin/SidebarItem';
  import Sidebar from '@/components/SidebarPlugin/SideBar';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';
  import { mapGetters } from 'vuex'

  export default {
    components: {
      DashboardNavbar,
      Notifications,
      'side-bar': Sidebar,
      'sidebar-item': SidebarItem,
      FadeTransition
    },
    computed: {
      ...mapGetters({
        user: 'authentication/getUser'
      })
    },
    mounted() {
      this.initScrollbar()
    },
    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      },
      hasPermission(role, targetRole) {
        return hasPermission(role, targetRole)
      },
      displaySidebar(val) {
        this.$store.commit('sidebar/displaySidebar', val)
      }
    }
  };
</script>
<style lang="scss">
</style>
