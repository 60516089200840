import moment from 'moment';
import api from './api';
import * as helpers from './helpers'

export default {
  namespaced: true,
  state: {
    scheduleStart: null,
    scheduleEnd: null,
    scheduleEnabled: false,
    weekendDeploysDisabled: false,
    originalInformation: null
  },
  mutations: {
    set(state, configurations) {
      state.scheduleStart = configurations.schedule_start;
      state.scheduleEnd = configurations.schedule_end;
      state.scheduleEnabled = helpers.parseBooleanConfiguration(configurations.schedule_enabled, false);
      state.weekendDeploysDisabled = helpers.parseBooleanConfiguration(configurations.weekend_deploys_disabled, false);
    },
    backup(state, configurations) {
      state.originalInformation = configurations
    }
  },
  getters: {
    get: state => ({
      scheduleStart: state.scheduleStart,
      scheduleEnd: state.scheduleEnd,
      scheduleEnabled: state.scheduleEnabled,
      weekendDeploysDisabled: state.weekendDeploysDisabled,
    }),
  },
  actions: {
    updateData(context, configurations) {
      const data = {};

      configurations.forEach((config) => {
        data[config.key] = config.value;
      });

      context.commit('backup', data)
      return context.commit('set', data);
    },
    get(context) {
      return api.get()
        .then(response => context.dispatch('updateData', response.data.data))
    },
    edit(context, data) {
      const payload = {
        weekend_deploys_disabled: data.weekendDeploysDisabled,
        schedule_enabled: data.scheduleEnabled,
        schedule_start: moment(data.scheduleStart, 'HH:mm').format('HH:mm:ss'),
        schedule_end: moment(data.scheduleEnd, 'HH:mm').format('HH:mm:ss'),
      };

      return api.update(payload)
        .then(response => context.dispatch('updateData', response.data.data))
    },
    reset(context) {
      context.commit('set', context.state.originalInformation)
    }
  },
};
