import Vue from 'vue'
import Vuex from 'vuex'
import applicationModule from './modules/application';
import authenticationModule from './modules/authentication';
import buildModule from './modules/build';
import configurationsModule from './modules/configuration';
import usersModule from './modules/user';
import slavesModule from './modules/slave';
import notificationModule from './modules/notification';
import sidebarModule from './modules/sidebar';
import api from '../plugins/axios'
import websocketPlugin from './plugins/websocket';
import buildsFiltersPlugin from './plugins/filters/build';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    application: applicationModule,
    authentication: authenticationModule,
    build: buildModule,
    configurations: configurationsModule,
    users: usersModule,
    slaves: slavesModule,
    notification: notificationModule,
    sidebar: sidebarModule,
  },
  state: {
    isModalOpen: false,
    statistics: {
      applications: '-',
      builds: '-',
      pending: '-',
      deploying: '-'
    },
    loading: 0,
  },
  mutations: {
    setModalStatus(state, isOpen) {
      state.isModalOpen = isOpen;
    },
    setStatistics(state, statistics) {
      state.statistics = statistics
    },
    addLoadingRequest(state) {
      state.loading++
    },
    removeLoadingRequest(state) {
      state.loading--
    }
  },
  getters: {
    isModalOpen: state => state.isModalOpen,
    statistics: state => state.statistics,
    loading: state => state.loading,
  },
  actions: {
    refreshStatistics(context) {
      return api.get('/api/v1/statistics')
        .then(response => context.commit('setStatistics', response.data.data))
    }
  },
  plugins: [
    (store) => {
      websocketPlugin.setStore(store)
    },
    buildsFiltersPlugin,
  ],
})
