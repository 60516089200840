export default (store) => {
  // eslint-disable-next-line consistent-return
  store.subscribe((mutation) => {
    // eslint-disable-next-line default-case
    switch (mutation.type) {
      case 'build/setFilters':
        return store.dispatch('build/getBuilds', mutation.payload);
    }
  });
};
