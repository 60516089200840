import moment from 'moment';
import * as R from 'ramda';

const isNullOrEmpty = R.either(R.isNil, R.isEmpty);

const keyExistsInObject = (object, key) => {
  if (isNullOrEmpty(object) || isNullOrEmpty(key)) {
    return false;
  }

  if (Object.prototype.hasOwnProperty.call(object, key)) {
    return !isNullOrEmpty(object[key]);
  }

  return false;
};

const formatDate = timestamp => moment(timestamp).format('DD/MM/YYYY HH:mm:ss');

const aclRoles = {
  READ: 1,
  WRITE: 2,
  ADMIN: 3,
};

const hasPermission = (userRole, requiredRole) => aclRoles[userRole] >= aclRoles[requiredRole];

export {
  formatDate,
  hasPermission,
  keyExistsInObject,
  isNullOrEmpty,
};
