import axios from '../../../plugins/axios';

const find = id => axios.get(`/api/v1/slaves/${id}`);

const get = params => axios.get('/api/v1/slaves', { params });

const create = data => axios.post('/api/v1/slaves', data);

const update = (id, data) => axios.put(`/api/v1/slaves/${id}`, data);

const revokeToken = id => axios.patch(`/api/v1/slaves/${id}/revoke-token`);

export default {
  find,
  get,
  create,
  update,
  revokeToken,
};
