import io from 'socket.io-client';
import config from '../../../config';

const CHANNELS = {
  NEW_BUILD: 'new_build',
  BUILD_STATE_CHANGE: 'build_state_change',
}

class WebSocketPlugin {
  constructor() {
    this._connected = false
    this._connecting = false
  }

  setToken(token) {
    this.token = token
    this.connect()
  }

  connect() {
    if (this._connected) {
      console.debug('Ignoring connect request, already connected')
      return
    }

    if (this._connecting) {
      console.debug('Ignoring connect request, already connecting')
      return
    }

    this._connecting = true
    this.socket = io(config.getParameter('api_url'), {
      transports: ['websocket'],
      auth: {
        token: `Bearer ${this.token}`
      }
    })

    this.socket.on('connect_error', (err) => {
      console.error('Failed to connect to websocket', err)
      this.reconnect()
    })

    this.socket.on('disconnect', (err) => {
      console.error('Disconnected from websocket', err)
      this.reconnect()
    })

    this.registerSubscribers()
    this._connected = true
    this._connecting = false
  }

  reconnect() {
    if (this._connected) {
      console.error('Ignoring reconnect request, already connected')
      return
    }

    if (this._connecting) {
      console.error('Ignoring reconnect request, already connecting')
      return
    }

    return this.connect()
  }

  setStore(store) {
    this.store = store

    this.store.subscribe((mutation) => {
      if (mutation.type === 'authentication/setToken') {
        this.setToken(mutation.payload)
      }
    })
  }

  registerSubscribers() {
    this.socket.on(CHANNELS.NEW_BUILD, (data) => {
      this.store.dispatch('build/refresh')
      this.store.dispatch('refreshStatistics')
      this.store.commit('notification/addToList', {
        type: 'new-build',
        data,
      })

      this._notify(data.state.toLowerCase(), data.release_name)
    });

    this.socket.on(CHANNELS.BUILD_STATE_CHANGE, (data) => {
      this.store.dispatch('build/refresh')
      this.store.dispatch('refreshStatistics')
      this.store.commit('notification/addToList', {
        type: 'build-state-change',
        data,
      })

      this._notify(data.state.toLowerCase(), data.release_name)
    });
  }

  _notify(state, release_name) {
    switch (state) {
      case 'error': {
        this.store.dispatch('notification/notify', {
          type: 'danger',
          message: `Error deploying ${release_name}`
        })
        break
      }
      case 'done': {
        this.store.dispatch('notification/notify', {
          type: 'success',
          message: `Build ${release_name} has been deployed`
        })
        break
      }
      default : {
        this.store.dispatch('notification/notify', {
          type: 'info',
          message: `Build ${release_name} is now ${state}`
        })
      }
    }
  }
}

export default new WebSocketPlugin()
