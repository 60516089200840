<template>
  <div class="footer">
    <b-row
      align-v="center"
      class="justify-content-lg-between"
    >
      <b-col lg="12">
        <div class="copyright text-center text-lg-right text-muted">
          © {{ year }} Release Management System
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style>

</style>
