export default {
  namespaced: true,
  state: {
    showSidebar: false,
    sidebarLinks: [],
    isMinimized: false,
  },
  mutations: {
    displaySidebar(state, value) {
      state.showSidebar = value
    },
    isMinimized(state, value) {
      state.isMinimized = value
    }
  },
  getters: {
    showSidebar: state => state.showSidebar,
    sidebarLinks: state => state.sidebarLinks,
    isMinimized: state => state.isMinimized,
  },
  actions: {
    toggleMinimize(context) {
      document.body.classList.toggle('sidebar-mini');
      // we simulate the window Resize so the charts will get updated in realtime.
      const simulateWindowResize = setInterval(() => {
        window.dispatchEvent(new Event('resize'));
      }, 180);

      // we stop the simulation of Window Resize after the animations are completed
      setTimeout(() => {
        clearInterval(simulateWindowResize);
      }, 1000);

      context.commit('isMinimized', !context.state.isMinimized)
    }
  }
}
