import * as R from 'ramda';
import api from './api';

export default {
  namespaced: true,
  state: {
    user: null,
    users: [],
    count: 0,
    filters: {
      page: 1,
      limit: 10,
    },
  },
  mutations: {
    setUsers(state, users) {
      state.users = users;
    },
    setUsersNumber(state, count) {
      state.count = count;
    },
    setUser(state, user) {
      state.user = user;
    },
    setFilters(state, filters) {
      state.filters = R.mergeDeepRight(state.filters, filters);
    },
  },
  getters: {
    getUser: state => state.user,
    getUsers: state => state.users,
    getUsersNumber: state => state.count,
    getFilters: state => state.filters,
  },
  actions: {
    getUsers(context, data = {}) {
      return api.get(R.mergeDeepRight(context.state.filters, data))
        .then((response) => {
          context.commit('setUsers', response.data.data)
          context.commit('setUsersNumber', response.data.meta.count)
        })
    },
    getUser(context, id) {
      return api.find(id)
        .then((response) => context.commit('setUser', response.data.data))
    },
    editUser(context) {
      const data = {
        is_active: !!context.state.user.is_active,
        name: context.state.user.name,
        email: context.state.user.email,
        role: context.state.user.role,
      };

      return api.update(context.state.user.id, data)
    },
  },
};
