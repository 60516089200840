import axios from '../../../plugins/axios';

const get = () => axios.get('/api/v1/configurations');

const update = data => axios.post('/api/v1/configurations', data);

export default {
  get,
  update,
};
