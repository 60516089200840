import api from './api';

export default {
  namespaced: true,
  state: {
    applications: [],
    count: 0,
    application: null
  },
  mutations: {
    setApplication(state, application) {
      state.application = application;
    },
    setApplications(state, applications) {
      state.applications = applications;
    },
    setApplicationsNumber(state, count) {
      state.count = count;
    }
  },
  getters: {
    getApplication: state => state.application,
    getApplications: state => state.applications,
    getApplicationsNumber: state => state.count,
  },
  actions: {
    getApplications(context, data = {}) {
      return api.get(data)
        .then((result) => {
          context.commit('setApplications', result.data.data);
          context.commit('setApplicationsNumber', result.data.meta.count);
        })
    },
    getApplication(context, id) {
      return api.find(id)
        .then((result) => {
          context.commit('setApplication', result.data.data);
        })
    },
    getBuildEnvironments(context, id) {
      return api.getBuildEnvironments(id)
    }
  }
}
