import axios from '../../../plugins/axios';

const find = id => axios.get(`/api/v1/users/${id}`);

const get = params => axios.get('/api/v1/users', { params });

const update = (id, data) => axios.put(`/api/v1/users/${id}`, data);

export default {
  find,
  get,
  update,
};
