import * as R from 'ramda';
import api from './api';

export default {
  namespaced: true,
  state: {
    slave: null,
    slaves: [],
    count: 0,
    filters: {
      page: 1,
      limit: 10,
    },
    showTokenModal: false,
  },
  mutations: {
    setSlaves(state, slaves) {
      state.slaves = slaves;
    },
    setSlavesNumber(state, count) {
      state.count = count;
    },
    setSlave(state, slave) {
      state.slave = slave;
    },
    showTokenModal(state, show) {
      state.showTokenModal = show;
    },
    setFilters(state, filters) {
      state.filters = R.mergeDeepRight(state.filters, filters);
    },
  },
  getters: {
    getSlave: state => state.slave,
    getSlaves: state => state.slaves,
    getSlavesNumber: state => state.count,
    getFilters: state => state.filters,
    showTokenModal: state => state.showTokenModal,
  },
  actions: {
    getSlaves(context, data = {}) {
      return api.get(R.mergeDeepRight(context.state.filters, data))
        .then((response) => {
          context.commit('setSlaves', response.data.data)
          context.commit('setSlavesNumber', response.data.meta.count)
        })
    },
    getSlave(context, id) {
      return api.find(id)
        .then((response) => context.commit('setSlave', response.data.data))
    },
    editSlave(context) {
      const data = {
        is_active: !!context.state.slave.is_active,
        deploys_disabled: !!context.state.slave.deploys_disabled,
        name: context.state.slave.name,
        cluster: context.state.slave.cluster,
        queue: context.state.slave.queue,
        queue_strategy: context.state.slave.queue_strategy,
        platform_dashboard_enabled: context.state.slave.platform_dashboard_enabled,
        platform_dashboard_region_uuid: context.state.slave.platform_dashboard_region_uuid,
      };

      return api.update(context.state.slave.id, data)
    },
    createSlave(context, data) {
      return api.create(data)
    },
    resetToken(context, data) {
      return api.revokeToken(data)
    }
  },
};
