<template>
  <base-dropdown
    class="nav-item"
    menu-on-right
    tag="li"
    title-tag="a"
  >
    <a
      slot="title-container"
      class="nav-link nav-link-icon"
      role="button"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="ni ni-bell-55" />
    </a>

    <a
      v-if="!notifications.length"
      class="dropdown-item"
    >No notifications</a>
    <a
      v-for="item in notifications"
      :key="item.key"
      class="dropdown-item"
      @click="openNotification(item)"
    >{{ item.title }}</a>
    <div
      v-if="notifications.length > 0"
      class="dropdown-divider"
    />
    <a
      v-if="notifications.length > 0"
      class="dropdown-item"
      @click="removeAll"
    >Remove all notifications</a>
  </base-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "NotificationsBell",
  computed: {
    ...mapGetters({
      notifications: 'notification/notifications'
    })
  },
  methods: {
    openNotification(notification) {
      this.$store.commit('notification/acknowledge', notification.key)
      return this.$router.push(notification.link)
    },
    removeAll() {
      this.$store.commit('notification/acknowledgeAll')
    }
  }
}
</script>

<style scoped>

</style>
