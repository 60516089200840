/*!
=========================================================
* BootstrapVue Argon Dashboard - v1.0.0
=========================================================

Product Page: https://www.creative-tim.com/product/bootstrap-vue-argon-dashboard
Copyright 2020 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com
*/
import Vue from 'vue'
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue'
import router from './router';
import store from './store'

Vue.use(DashboardPlugin);

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
