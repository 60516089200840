<template>
  <div class="notifications">
    <slide-y-up-transition
      :duration="transitionDuration"
      group
      mode="out-in"
    >
      <notification
        v-for="notification in notifications"
        :key="notification.timestamp.getTime()"
        v-bind="notification"
        :click-handler="notification.onClick"
        @close="removeNotification"
      />
    </slide-y-up-transition>
  </div>
</template>
<script>
  import {mapGetters} from 'vuex'
  import Notification from './Notification.vue';
  import { SlideYUpTransition } from 'vue2-transitions';

  export default {
    components: {
      SlideYUpTransition,
      Notification
    },
    props: {
      transitionDuration: {
        type: Number,
        default: 2000
      },
      overlap: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters({
        notifications: 'notification/state',
        settings: 'notification/settings',
      })
    },
    watch: {
      overlap: function (newVal) {
        this.$store.commit('notification/setOptions', {overlap: newVal})
      }
    },
    created() {
      this.$store.commit('notification/setOptions', {overlap: this.overlap})
    },
    methods: {
      removeNotification(timestamp) {
        this.$store.commit('notification/removeNotification', timestamp)
      }
    }
  };
</script>
