<template>
  <nav
    id="sidenav-main"
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white"
  >
    <div class="container-fluid">
      <!--Toggler-->
      <navbar-toggle-button @click.native="openSidebar" />
      <router-link
        class="navbar-brand"
        to="/"
      >
        <img
          :src="logo"
          class="navbar-brand-img"
          alt="..."
        >
      </router-link>

      <slot name="mobile-right">
        <ul class="nav align-items-center d-md-none">
          <NotificationsBell />
          <base-dropdown
            class="nav-item"
            menu-on-right
            tag="li"
            title-tag="a"
          >
            <a
              slot="title-container"
              class="nav-link"
              href="#"
              role="button"
            >
              <div class="media align-items-center">
                <span class="avatar avatar-sm rounded-circle">
                  <img
                    alt="Image placeholder"
                    :src="user.metadata.picture"
                  >
                </span>
              </div>
            </a>

            <div class=" dropdown-header noti-title">
              <h6 class="text-overflow m-0">
                Welcome!
              </h6>
            </div>
            <a
              class="dropdown-item"
              @click="logout"
            >
              <i class="ni ni-user-run" />
              <span>Logout</span>
            </a>
          </base-dropdown>
        </ul>
      </slot>
      <slot />
      <div
        v-show="showSidebar"
        id="sidenav-collapse-main"
        class="navbar-collapse collapse show"
      >
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img :src="logo">
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button @click.native="closeSidebar" />
            </div>
          </div>
        </div>

        <ul class="navbar-nav">
          <slot name="links" />
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
  import NavbarToggleButton from '@/components/NavbarToggleButton'
  import NotificationsBell from '@/components/NotificationsBell'
  import {mapGetters} from 'vuex'

  export default {
    name: 'Sidebar',
    components: {
      NavbarToggleButton,
      NotificationsBell
    },
    props: {
      logo: {
        type: String,
        default: '/img/logo.png',
        description: 'Sidebar app logo'
      },
      autoClose: {
        type: Boolean,
        default: true,
        description: 'Whether sidebar should autoclose on mobile when clicking an item'
      }
    },
    computed: {
      ...mapGetters({
        user: 'authentication/getUser',
        showSidebar: 'sidebar/showSidebar'
      })
    },
    provide() {
      return {
        autoClose: this.autoClose
      };
    },
    beforeDestroy() {
      if (this.showSidebar) {
        this.closeSidebar()
      }
    },
    methods: {
      closeSidebar() {
        this.$store.commit('sidebar/displaySidebar', false)
      },
      openSidebar() {
        this.$store.commit('sidebar/displaySidebar', true)
      },
      logout() {
        localStorage.removeItem('token')
        return this.$router.push('/')
      }
    }
  };
</script>

<style>
.navbar-vertical .navbar-brand {
  overflow: hidden;
  margin-left: -10%;
  margin-right: -10%;
}
</style>
