// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import '@/polyfills';
// Notifications plugin. Used on Notifications page
// A plugin file where you could register global components used across the app
import GlobalComponents from './global-components';
// A plugin file where you could register global directives
import GlobalDirectives from './global-directives';
// vue-bootstrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Validation plugin used to validate forms
import { configure } from 'vee-validate'
// Globally import loading component
import VueElementLoading from 'vue-element-loading'

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

// Element ui language configuration
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
locale.use(lang)

// asset imports
import '@/assets/scss/argon.scss'
import '@/assets/vendor/nucleo/css/nucleo.css'
import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/en.json'

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  })
})

export default {
  install(Vue) {
    Vue.component('VueElementLoading', VueElementLoading)
    Vue.use(GlobalDirectives)
    Vue.use(GlobalComponents)
    Vue.use(BootstrapVue)
    Vue.use(IconsPlugin)
    Vue.use(flatPickr)
    configure({
      classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
        dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
      }
    })
  }
}
